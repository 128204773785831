
import { CToast, CToaster, CToastHeader } from "@coreui/vue-pro/src/components/toast";
import { CToastBody } from "@coreui/vue-pro/src/components/toast";
import { defineComponent } from "vue";

export default defineComponent({
  components: {CToastBody, CToastHeader, CToast, CToaster},
  computed: {
    toasts() {
      // @ts-ignore
      return []
      // TODO: fix me pls -> return JSON.parse(JSON.stringify(this.$store.state.notifications));
    }
  },
  methods: {
    /*
    createToast({title, content, color}: ToastMessage) {
      color = color ?? 'default';
      // TODO: fix me pls -> pushes notifications more than once
      // this.toasts.push({title, content, color});
    }
     */
  }
});
