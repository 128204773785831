import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_view = _resolveComponent("router-view")!
  const _component_toastr_component = _resolveComponent("toastr-component")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    ($setup.isReady)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_component_router_view)
        ]))
      : _createCommentVNode("", true),
    _createVNode(_component_toastr_component)
  ], 64))
}