
import { onMounted, ref } from 'vue'
import { RouteLocationMatched } from 'vue-router'
import router from '@/router'

export default {
  name: 'AppBreadcrumb',
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  setup() {
    const breadcrumbs = ref()

    const getBreadcrumbs = () => {
      return router.currentRoute.value.matched.map(
        (route: RouteLocationMatched) => {
          return {
            active: route.path === router.currentRoute.value.fullPath,
            name: route.name,
            path: `${router.options.history.base}${route.path}`,
          }
        },
      )
    }

    router.afterEach(() => {
      breadcrumbs.value = getBreadcrumbs()
    })

    onMounted(() => {
      breadcrumbs.value = getBreadcrumbs()
    })

    return {
      breadcrumbs,
    }
  },
}
