export default {
  isTestSystem: true,
  host: (function () {
    // if we access through localhost, use dev port and host
    if (window.location.hostname.indexOf('localhost') > -1) {
      return 'http://localhost:5247';
    }

    // otherwise use production path
    return 'https://ruetli.rangeurchef.ch';
  }()),
  path: function (path: string, query: object | string | null = null) {
    if (query !== null && typeof query === 'object') {
      Object.keys(query).forEach(k => {
        // @ts-ignore
        if (query[k] === null) delete (query[k]);
      });

      query = new URLSearchParams(query as any).toString();
    }

    query = query === null ? '' : '?' + query;

    if (!path.startsWith('/')) {
      path = `/${ path }`;
    }

    return `${ this.host }${ path }${ query }`;
  }
};
