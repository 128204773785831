import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';

import CoreuiVue from '@coreui/vue-pro';
import { CIcon } from '@coreui/icons-vue';
import { iconsSet as icons } from '@/assets/icons';

import { createI18n } from 'vue-i18n';

const app = createApp(App);
app.use(store);
app.config.globalProperties.$store = store;

app.use(router);
app.use(CoreuiVue);

const i18n = createI18n({
  locale: 'de',
  fallbackLocale: 'de',
  messages: {
    de: require('./i18n/de.json')
  }
});
app.use(i18n);

app.provide('icons', icons);
app.component('CIcon', CIcon);

app.mount('#app');
