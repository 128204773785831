import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import config from "@/config";
import store from '@/store';


interface AxiosAuthRefreshCache {
  skipInstances: AxiosRequestConfig[];
}

const cache: AxiosAuthRefreshCache = {
  skipInstances: [],
};

export const setupAxios = () => {
  axios.interceptors.request.use(config => {
    config.withCredentials = true;

    if (!config.headers) {
      config.headers = {};
    }

    const token = store.state.accessToken;

    if (typeof token === 'undefined' || token === null || token.length === 0) {
      return config;
    }

    config.headers['Accept'] = 'application/json';
    config.headers['Authorization'] = 'Bearer ' + token;

    return config;
  });

  // reference: https://github.com/Flyrell/axios-auth-refresh
  axios.interceptors.response.use(
    response => response,
    (error: AxiosError) => {
      // do not immediately reject if auth fails (Unauthorized), we try to handle this error and re-authenticate
      if (error.response?.status !== 401 || !(error.response.headers['www-authenticate']?.startsWith('Bearer error="invalid_token"'))) {
        return Promise.reject(error);
      }

      if (cache.skipInstances.length > 0) {
        return;
      }

      // if the renew access token request failed, just do not proceed (will otherwise result in an endless loop)
      if (error.response.config.url?.endsWith('/api/auth/renew-token')) {
        window.location.reload();
        return;
      }

      const refreshing = axios.post(config.path('/api/auth/renew-token'), {
        token: store.state.accessToken,
      });

      return refreshing
        .then((response: AxiosResponse<{ token: string }>) => {
          store.commit('accessToken', response?.data?.token);

          cache.skipInstances.map(x => axios(x));
          cache.skipInstances = [];

          if (error?.response?.config) return axios(error.response.config);
        })
        .catch(error => {
          console.error(error);
          // #126: if the renewal for the token fails, make sure there is no token in the state store
          // this makes sure the users _must_ authenticate again
          store.commit('accessToken', null);
          cache.skipInstances = [];

          return Promise.reject(error);
        });
    });
}
