import { createStore } from 'vuex';
import VuexPersistence from 'vuex-persist';
import { ToastMessage } from '@/types/toastMessage';
import jwtDecode from 'jwt-decode';
import { JwtToken } from '@/store/jwtToken';

export interface AppState {
  accessToken: string | null;
  asideVisible: boolean;
  sidebarVisible: boolean;
  sidebarUnfoldable: boolean;
  theme: 'dark' | 'default';
  notifications: ToastMessage[];
}

const persistence = new VuexPersistence<AppState>({
  storage: window.localStorage,
  filter: (mutation) => mutation.type !== 'sendNotification',
});

export default createStore({
  state: {
    accessToken: null,
    asideVisible: false,
    sidebarVisible: true,
    sidebarUnfoldable: false,
    theme: 'default',
    notifications: [],
  },
  plugins: [persistence.plugin],
  mutations: {
    toggleAside(state) {
      state.asideVisible = !state.asideVisible;
    },
    toggleSidebar(state) {
      console.log(state);
      state.sidebarVisible = !state.sidebarVisible;
    },
    toggleTheme(state, payload) {
      state.theme = payload.value;
    },
    toggleUnfoldable(state) {
      state.sidebarUnfoldable = !state.sidebarUnfoldable;
    },
    updateSidebarVisible(state, payload) {
      state.sidebarVisible = payload.value;
    },
    accessToken(state, payload) {
      state.accessToken = payload;
    },
    sendNotification(state, payload: ToastMessage) {
      state.notifications.push(payload);
    }
  },
  actions: {},
  modules: {},
  getters: {
    notifications: (state) => state.notifications,
    isAdminUser: (state) => jwtDecode<JwtToken>(state.accessToken ?? '', { header: false })?.role.indexOf('admin'),
  }
});
