
import { watch } from 'vue';
import { useStore } from 'vuex';
import { AppState } from "@/store";
import { useRouter } from "vue-router";
import { setupAxios } from "@/services/axios";
import ToastrComponent from "@/views/components/ToastrComponent.vue";

export default {
  components: {ToastrComponent},
  setup() {
    const store = useStore<AppState>();
    const router = useRouter();

    watch(store.state, () => {
      store.state.theme === 'dark'
          ? document.body.classList.add('dark-theme')
          : document.body.classList.remove('dark-theme');
    });
    store.state.theme === 'dark'
        ? document.body.classList.add('dark-theme')
        : document.body.classList.remove('dark-theme');

    if (location.hash && location.hash.startsWith('#token=')) {
      store.commit('accessToken', location.hash.substring('#token='.length));
    }

    setupAxios();

    if ((router.currentRoute.value.path.startsWith('/pages/') || router.currentRoute.value.path === '/')
        && window.location.toString().indexOf('/page') !== -1) {
      return {
        isReady: true,
      };
    }

    if (!store.state.accessToken) {
      router.push({name: 'login'});
    }

    return {
      isReady: true
    };
  },
};
